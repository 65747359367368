import React from 'react';
import Grid from '@material-ui/core/Grid';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { UserProfileCard } from './User/UserProfile';
import { SkillExchangeUserEntitySkillsCard } from '@spotify/backstage-plugin-skill-exchange';
import { EntityWarningContent } from '../EntityWarningContent';

export const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <UserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SkillExchangeUserEntitySkillsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
