import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useGetEntities } from './useGetEntities';
import { ICONS, TYPE_ORDER, GRADIENTS } from './constants';
import pluralize from 'pluralize';

const useStyles = makeStyles<Theme, { color1: string; color2: string }>(
  theme => ({
    boxContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      minWidth: 210,
      background: props =>
        `linear-gradient(35deg, ${props.color1}, ${props.color2})`,
    },
    progress: {
      minHeight: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

interface TypeItemProps {
  icon: React.ElementType;
  name: string;
  count: number;
  url: string;
}

const TypeItem = (props: TypeItemProps) => {
  const { icon: Icon, name, count, url } = props;

  const gradient =
    GRADIENTS[name as keyof typeof GRADIENTS] || GRADIENTS.default;

  const classes = useStyles({ color1: gradient[0], color2: gradient[1] });

  return (
    <Paper elevation={5} className={classes.boxContainer}>
      <Icon fontSize="large" />
      <Link
        style={{
          color: 'white',
        }}
        href={url}
      >
        <Tooltip
          placement="top"
          arrow
          title={`View all ${pluralize(name, count)} owned by your teams`}
        >
          <Typography variant="h6">{pluralize(name, count)}</Typography>
        </Tooltip>
      </Link>
      <Typography variant="subtitle1">{count}</Typography>
    </Paper>
  );
};

interface OwnershipCardProps {
  relationAggregation?: 'direct' | 'aggregated';
}

export const OwnershipCard = (props: OwnershipCardProps) => {
  const classes = useStyles({ color1: '#FFC107', color2: '#FF9800' }); // colors not used here

  const { entity } = useEntity();
  const defaultRelationAggregation =
    entity.kind === 'User' ? 'aggregated' : 'direct';

  const [getRelationAggregation, setRelationAggregation] = useState(
    props.relationAggregation ?? defaultRelationAggregation,
  );

  const { componentsWithCounters, loading, error } = useGetEntities(
    entity,
    getRelationAggregation,
    [],
    10,
  );

  const finalItems =
    componentsWithCounters
      ?.map(c => ({
        icon: ICONS[c.type as keyof typeof ICONS] || ICONS.default,
        name: c.type,
        count: c.counter,
        url: `/catalog/?${c.queryParams}`,
      }))
      .sort(
        (a, b) => TYPE_ORDER.indexOf(a.name) - TYPE_ORDER.indexOf(b.name),
      ) || [];

  if (loading) {
    <Box className={classes.progress}>
      <Progress />
    </Box>;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <>
      <Box display="flex" alignItems="flex-start">
        <Box>
          <Typography variant="h5">Team Ownership</Typography>
          <Typography variant="body1">
            All the things your teams own.
          </Typography>
        </Box>
        <Box
          ml={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center">
            <Typography>Direct</Typography>
            <Tooltip
              placement="top"
              arrow
              title={`${
                getRelationAggregation === 'direct' ? 'Direct' : 'Aggregated'
              } Relations`}
            >
              <Switch
                color="primary"
                checked={getRelationAggregation !== 'direct'}
                onChange={() => {
                  const updatedRelationAggregation =
                    getRelationAggregation === 'direct'
                      ? 'aggregated'
                      : 'direct';
                  setRelationAggregation(updatedRelationAggregation);
                }}
                name="pin"
                inputProps={{ 'aria-label': 'Ownership Type Switch' }}
              />
            </Tooltip>
            <Typography>Aggregated</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'start',
        }}
      >
        {finalItems.map((item, idx) => (
          <TypeItem key={idx} {...item} />
        ))}
      </Box>
    </>
  );
};
