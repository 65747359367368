import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { homePlugin } from '@backstage/plugin-home';
import { createReactExtension } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
    '& fieldset': {
      border: 'none',
    },
  },
}));

export const SearchBar = () => {
  const classes = useStyles();
  return (
    <HomePageSearchBar
      classes={{ root: classes.searchBar }}
      placeholder="Search"
    />
  );
};

export const HomeSearchBar = homePlugin.provide(
  createReactExtension({
    name: 'Search Bar',
    component: {
      sync: SearchBar,
    },
    data: {
      'home.widget.config': {
        layout: {
          height: { minRows: 1 },
          width: { minColumns: 2 },
        },
      },
    },
  }),
);
