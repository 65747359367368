import React, { PropsWithChildren } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import { RVOHHorizontaWhitelLogo, RVOHMarkLogo } from '../logos';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSearch } from '@backstage/plugin-search';
import { FeatureFlagged } from '@backstage/core-app-api';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import { RBACSidebarItem } from '@spotify/backstage-plugin-rbac';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SchoolIcon from '@material-ui/icons/School';
import HistoryIcon from '@material-ui/icons/History';
import CloudCircle from '@material-ui/icons/CloudCircle';
import GroupIcon from '@material-ui/icons/Group';
import BuildIcon from '@material-ui/icons/Build';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  svg: {
    width: 'auto',
    height: 25,
  },
  path: {
    fill: '#ffffff',
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { svg, path } = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? (
          <RVOHHorizontaWhitelLogo classes={{ svg, path }} />
        ) : (
          <RVOHMarkLogo classes={{ svg, path }} />
        )}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarSearch />
      <SidebarDivider />
      <SidebarItem icon={HomeIcon} to="" text="Home" />
      <SidebarItem icon={GroupIcon} to="teams" text="Teams" />
      <SidebarItem icon={ExtensionIcon} to="catalog" text="Catalog" />
      <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
      <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
      <SidebarItem icon={BuildIcon} to="tools" text="Tools" />
      <SidebarItem icon={TrackChangesIcon} to="tech-radar" text="Tech Radar" />
      <SidebarItem
        icon={CloudCircle}
        to="cloud-accounts"
        text="Cloud Accounts"
      />
      <SidebarItem
        icon={AccessibilityIcon}
        to="web-accessibility"
        text="Web Accessibility"
      />
      <SidebarItem
        icon={AnnouncementIcon}
        to="announcements"
        text="Announcements"
      />
      <SidebarItem icon={SchoolIcon} to="mentorship" text="Mentorship" />
      <FeatureFlagged with="qeta">
        <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&A" />
      </FeatureFlagged>
      <SidebarItem icon={DoneAllIcon} to="soundcheck" text="Soundcheck" />
      <RBACSidebarItem />
      {/* End global nav */}
      <SidebarDivider />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarItem icon={HistoryIcon} to="create/tasks" text="Template Tasks" />
      <Shortcuts />
      <SidebarSettings />
    </Sidebar>
    {children}
  </SidebarPage>
);
