import React from 'react';
import Grid from '@material-ui/core/Grid';
import { EntityLayout } from '@backstage/plugin-catalog';
import { OwnershipCard } from './Cards/Group/EntityOwnershipCard/OwnershipCard';
import { MembersListCard } from './Cards/Group/Memberlist/CustomMemberlistCard';
import { GroupProfileCard } from './Cards/Group/GroupProfile/CustomGroupProfileCard';
import { GroupSoundcheckContent } from '@spotify/backstage-plugin-soundcheck';
import { EntityWarningContent } from '../EntityWarningContent';
import {
  EntityGroupSecurityMetricsTab,
  EntitySecurityOverview,
} from '@internal/security-metrics';
import { EntityDXDashboardContent } from '@get-dx/backstage-plugin';
import {
  EntityCloudAccountsGroupTab,
  EntityCloudAccountsGroupTrendCard,
} from '@internal/cloud-accounts';
import { EntityTeamConfigTab } from '@internal/plugin-teams';
import { EntityTeamPagerDutyTab } from '@internal/incidents';
import { HorizontalScrollGrid } from '@backstage/core-components';
import { EntityIncidentsOverview } from '@internal/incidents';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
import {
  EntityWebAccessibilityTab,
  EntityWebAccessibilityOverview,
} from '@internal/web-accessibility';

export const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} spacing={3}>
          <HorizontalScrollGrid>
            <GroupProfileCard />
            <EntitySecurityOverview />
            <EntityCloudAccountsGroupTrendCard />
            <EntityIncidentsOverview />
            <EntityWebAccessibilityOverview />
          </HorizontalScrollGrid>
        </Grid>
        <Grid item xs={12}>
          <OwnershipCard relationAggregation="aggregated" />
        </Grid>
        <Grid item xs={12}>
          <MembersListCard showAggregateMembersToggle />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-metrics" title="Security">
      <EntityGroupSecurityMetricsTab />
    </EntityLayout.Route>
    <EntityLayout.Route path="/cloud-cost" title="Cloud Cost">
      <EntityCloudAccountsGroupTab />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dora" title="DORA">
      <EntityDXDashboardContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/soundcheck" title="Soundcheck">
      <GroupSoundcheckContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pagerduty" title="PagerDuty">
      <EntityTeamPagerDutyTab />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pulls" title="Pulls">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/web-accessibility" title="Web Accessibility">
      <EntityWebAccessibilityTab />
    </EntityLayout.Route>
    <EntityLayout.Route path="/config" title="Team Config">
      <EntityTeamConfigTab />
    </EntityLayout.Route>
  </EntityLayout>
);
