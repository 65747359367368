import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';

export type BaseTileProps = {
  title: string;
  headerAction?: React.ReactNode;
  cardActions?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const BaseTile = (props: BaseTileProps) => {
  return (
    <Card
      style={{
        minWidth: '300px',
        marginRight: '20px',
        width: '300px',
        ...props.style,
      }}
    >
      <CardHeader title={props.title} action={props.headerAction ?? null} />
      <CardContent>{props.children}</CardContent>
      {props.cardActions && <CardActions>{props.cardActions}</CardActions>}
    </Card>
  );
};
