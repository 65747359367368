import React from 'react';
import {
  GroupEntity,
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { MetricCard } from '@internal/plugin-common-react';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';

export const GroupProfileCard = () => {
  const { entity: group } = useEntity<GroupEntity>();

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  let visualizedChildRelations;
  let additionalChildren = 0;

  if (childRelations.length > 5) {
    visualizedChildRelations = childRelations.slice(0, 5);
    additionalChildren = childRelations.length - 5;
  } else {
    visualizedChildRelations = childRelations;
  }

  return (
    <MetricCard title="Details" learnMorePath="/teams/explorer">
      <ListItemText
        primary={
          parentRelations.length ? (
            <EntityRefLinks entityRefs={parentRelations} defaultKind="Group" />
          ) : (
            'N/A'
          )
        }
        secondary="Parent Group"
      />
      <ListItemText
        primary={
          childRelations.length ? (
            <>
              <EntityRefLinks
                entityRefs={visualizedChildRelations}
                defaultKind="Group"
              />
              {additionalChildren > 0 && (
                <span>{` and ${additionalChildren} more`}</span>
              )}
            </>
          ) : (
            'N/A'
          )
        }
        secondary="Child Groups"
      />
    </MetricCard>
  );
};
