import React, { useState } from 'react';
import {
  DEFAULT_NAMESPACE,
  GroupEntity,
  UserEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  entityRouteParams,
  useEntity,
} from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { generatePath } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  getAllDesendantMembersForGroupEntity,
  removeDuplicateEntitiesFrom,
} from './helpers';
import {
  Avatar,
  InfoCard,
  Progress,
  ResponseErrorPanel,
  Link,
  OverflowTooltip,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[3],
      borderRadius: '4px',
      overflow: 'visible',
      position: 'relative',
      margin: theme.spacing(4, 1, 1),
      flex: '1',
      minWidth: '0px',
    },
  }),
);

const MemberComponent = (props: { member: UserEntity }) => {
  const classes = useStyles();
  const {
    metadata: { name: metaName, description },
    spec: { profile },
  } = props.member;
  const displayName = profile?.displayName ?? metaName;

  return (
    <Grid item container xs={12} sm={6} md={2} xl={2}>
      <Box className={classes.card}>
        <Box
          display="flex"
          flexDirection="column"
          m={2}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            displayName={displayName}
            picture={profile?.picture}
            customStyles={{
              position: 'absolute',
              top: '-2rem',
            }}
          />
          <Box
            pt={2}
            sx={{
              width: '80%',
            }}
            textAlign="center"
          >
            <Typography variant="subtitle1">
              <Link
                data-testid="user-link"
                to={generatePath(
                  `/catalog/:namespace/user/${metaName}`,
                  entityRouteParams(props.member),
                )}
              >
                <OverflowTooltip text={displayName} />
              </Link>
            </Typography>
            {props.member.metadata.annotations?.['rvohealth.com/job-title'] && (
              <Typography variant="subtitle2">
                {props.member.metadata.annotations?.['rvohealth.com/job-title']}
              </Typography>
            )}
            {description && (
              <Typography variant="subtitle2">{description}</Typography>
            )}
            <Link
              style={{
                position: 'absolute',
                right: '0',
                top: '0',
                padding: '0.5rem',
              }}
              to="https://rvohealth.freshservice.com/support/catalog/items/109"
            >
              <DeleteIcon />
            </Link>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

/** @public */
export const MembersListCard = (props: {
  pageSize?: number;
  showAggregateMembersToggle?: boolean;
}) => {
  const { pageSize = 50, showAggregateMembersToggle } = props;

  const { entity: groupEntity } = useEntity<GroupEntity>();
  const {
    metadata: { name: groupName, namespace: grpNamespace },
  } = groupEntity;
  const catalogApi = useApi(catalogApiRef);

  const groupNamespace = grpNamespace || DEFAULT_NAMESPACE;

  const [page, setPage] = React.useState(1);
  const pageChange = (_: React.ChangeEvent<unknown>, pageIndex: number) => {
    setPage(pageIndex);
  };

  const [showAggregateMembers, setShowAggregateMembers] = useState(true);

  const { loading: loadingDescendantMembers, value: descendantMembers } =
    useAsync(async () => {
      if (!showAggregateMembersToggle) {
        return [] as UserEntity[];
      }

      return await getAllDesendantMembersForGroupEntity(
        groupEntity,
        catalogApi,
      );
    }, [catalogApi, groupEntity, showAggregateMembersToggle]);

  const {
    loading,
    error,
    value: directMembers,
  } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'relations.memberof': [
          stringifyEntityRef({
            kind: 'group',
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });

    return membersList.items as UserEntity[];
  }, [catalogApi, groupEntity]);

  const members = removeDuplicateEntitiesFrom(
    [
      ...(directMembers ?? []),
      ...(descendantMembers && showAggregateMembers ? descendantMembers : []),
    ].sort((a, b) =>
      stringifyEntityRef(a).localeCompare(stringifyEntityRef(b)),
    ),
  ) as UserEntity[];

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const nbPages = Math.ceil((members?.length || 0) / pageSize);

  const pagination = (
    <Pagination
      count={nbPages}
      page={page}
      onChange={pageChange}
      showFirstButton
      showLastButton
    />
  );

  const title = (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography style={{ marginRight: 5 }} variant="h5">
          Members ({members?.length || 0})
        </Typography>
        {showAggregateMembersToggle && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={showAggregateMembers}
                  onChange={() => {
                    setShowAggregateMembers(!showAggregateMembers);
                  }}
                  inputProps={{ 'aria-label': 'Users Type Switch' }}
                />
              }
              label="Show Aggregate Members"
            />
          </FormGroup>
        )}
      </Box>
      <Box>
        <Typography variant="body1">
          <Link to="https://rvohealth.freshservice.com/support/catalog/items/108">
            <OverflowTooltip text="Add user" />
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <InfoCard title={title} actions={pagination}>
      {showAggregateMembers && loadingDescendantMembers ? (
        <Progress />
      ) : (
        <Grid container spacing={1}>
          {members && members.length > 0 ? (
            members
              .slice(pageSize * (page - 1), pageSize * page)
              .map(member => (
                <MemberComponent member={member} key={member.metadata.uid} />
              ))
          ) : (
            <Box p={2}>
              <Typography>This group has no members.</Typography>
            </Box>
          )}
        </Grid>
      )}
    </InfoCard>
  );
};
