import { createApiRef, ConfigApi, FetchApi } from '@backstage/core-plugin-api';

export const entityMetricsApiRef = createApiRef<entityMetricsApi>({
  id: 'plugin.entity-metrics',
});

type ReposResponse = {
  items: Map<string, string[]>;
};

export class RequestError extends Error {
  resp: Response;
  status: number;

  constructor(resp: Response) {
    super(`entityMetrics request failed with a ${resp.status} status code`);
    this.resp = resp;
    this.status = resp.status;

    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export interface entityMetricsApi {
  getUnassignedRepos(): Promise<ReposResponse>;
  getUnregisteredRepos(): Promise<ReposResponse>;
}

export class EntityMetricsClient implements entityMetricsApi {
  private readonly baseURL: string;
  private readonly fetchApi: FetchApi;

  constructor(options: { configApi: ConfigApi; fetchApi: FetchApi }) {
    // TODO: implement discovery service?
    this.baseURL = options.configApi.getString('backend.baseUrl');
    this.fetchApi = options.fetchApi;
  }

  async getUnassignedRepos(): Promise<ReposResponse> {
    const resp = await this.do<undefined, ReposResponse>(
      'unassigned-repos',
      'GET',
      undefined,
    );
    return resp!;
  }

  async getUnregisteredRepos(): Promise<ReposResponse> {
    const resp = await this.do<undefined, ReposResponse>(
      'unregistered-repos',
      'GET',
      undefined,
    );
    return resp!;
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
  ): Promise<R | null> {
    const url = new URL(`/api/entity-metrics/${path}`, this.baseURL);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    const resp = await this.fetchApi.fetch(url.toString(), {
      method,
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new RequestError(resp);
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
