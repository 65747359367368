import {
  createPlugin,
  createApiFactory,
  configApiRef,
  identityApiRef,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { cloudAccountsApiRef, CloudAccountsClient } from './api/cloudAccounts';

import { rootRouteRef } from './routes';

export const cloudAccountsPlugin = createPlugin({
  id: 'cloud-accounts',
  apis: [
    createApiFactory({
      api: cloudAccountsApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => {
        return new CloudAccountsClient({ identityApi, configApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const CloudAccountsPage = cloudAccountsPlugin.provide(
  createRoutableExtension({
    name: 'CloudAccountsPage',
    component: () =>
      import('./components/CloudAccountsPage').then(m => m.CloudAccountsPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityCloudAccountsGroupTab = cloudAccountsPlugin.provide(
  createComponentExtension({
    name: 'EntityCloudAccountsGroupTab',
    component: {
      lazy: () =>
        import('./components/EntityComponents/EntityCloudAccountsTab').then(
          m => m.EntityCloudAccountsTab,
        ),
    },
  }),
);
export const EntityCloudAccountsGroupTrendCard = cloudAccountsPlugin.provide(
  createComponentExtension({
    name: 'EntityCloudAccountsGroupTrendCard',
    component: {
      lazy: () =>
        import(
          './components/EntityComponents/EntityCloudAccountsGroupTrendCard'
        ).then(m => m.EntityCloudAccountsGroupTrendCard),
    },
  }),
);

export const CloudZeroAccountLink = cloudAccountsPlugin.provide(
  createComponentExtension({
    name: 'CloudZeroAccountLink',
    component: {
      lazy: () =>
        import('./components/CloudZeroLinks').then(m => m.CloudZeroAccountLink),
    },
  }),
);
