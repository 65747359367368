import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
  identityApiRef,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { teamsApiRef, TeamsClient } from './api/teams';
import { notificationsRouteRef, teamsRouteRef } from './routes';

export const teamsPlugin = createPlugin({
  id: 'teams',
  apis: [
    createApiFactory({
      api: teamsApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => {
        return new TeamsClient({ identityApi, configApi });
      },
    }),
  ],
});

export const EntityTeamConfigTab = teamsPlugin.provide(
  createComponentExtension({
    name: 'EntityTeamConfigTab',
    component: {
      lazy: () =>
        import('./components/EntityTeamConfigTab').then(
          m => m.EntityTeamConfigTab,
        ),
    },
  }),
);

export const NotificationsPage = teamsPlugin.provide(
  createRoutableExtension({
    name: 'NotificationsPage',
    component: () =>
      import('./components/NotificationsPage').then(m => m.NotificationsPage),
    mountPoint: notificationsRouteRef,
  }),
);

export const TeamsPage = teamsPlugin.provide(
  createRoutableExtension({
    name: 'TeamsPage',
    component: () => import('./components/TeamsPage').then(m => m.TeamsPage),
    mountPoint: teamsRouteRef,
  }),
);
