import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import { TechRadarLoaderResponse } from '@backstage-community/plugin-tech-radar-common';
import { IdentityApi, ConfigApi } from '@backstage/core-plugin-api';

export class MyOwnClient implements TechRadarApi {
  constructor(configApiRef: ConfigApi, identityApi: IdentityApi) {
    this.configApi = configApiRef;
    this.identityApi = identityApi;
  }

  private identityApi;
  private configApi;

  async load(): Promise<TechRadarLoaderResponse> {
    const backendUrl = this.configApi.getString('backend.baseUrl');

    const { token } = await this.identityApi.getCredentials();
    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const radarResponse = await fetch(`${backendUrl}/api/tech-radar/radar`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });

    const radar = (await radarResponse.json()) as TechRadarLoaderResponse;

    return {
      ...radar,
      entries: radar.entries.map(entry => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
