import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { Octokit } from '@octokit/rest';
import useDebounce from 'react-use/lib/useDebounce';
import { useGithubToken } from '../utils';

export const GithubRepoPicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    schema: { title = 'Github Repo', description = 'Github Repo' },
    required,
    rawErrors = [],
    formData = {},
  } = props;

  const [formOptions, setFormOptions] = useState<string[]>([]);
  const formContext = props.formContext;
  const [input, setInput] = useState('');

  const token = useGithubToken();
  const octokit = new Octokit({ auth: token });

  useDebounce(
    () => {
      getGitHubReposFromOrg(input).then(repos => {
        setFormOptions(repos);
      });
    },
    200,
    [input],
  );

  async function getGitHubReposFromOrg(r: string): Promise<string[]> {
    try {
      const response = await octokit.request('GET /search/repositories', {
        headers: {
          Accept: 'application/vnd.github.v3+json',
        },
        q: `${r} in:name org:${formContext.formData.org ?? 'rvohealth'}`,
        per_page: 100,
      });

      const repos: string[] = [];
      for (const repo of response.data.items) {
        repos.push(repo.name);
      }

      return repos;
    } catch (e) {
      rawErrors.push(
        'There was an error fetching repos from github. Please try to refresh or contact the developer platform team.',
      );
      return [];
    }
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors.length > 0 && !formData}
    >
      <Autocomplete
        onInputChange={(_, value) => setInput(value)}
        loading={false}
        options={formOptions || []}
        autoSelect
        freeSolo
        key={formContext.formData.org}
        onChange={(_event, value) => {
          onChange(value || '');
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
            placeholder="Repositories"
          />
        )}
      />
    </FormControl>
  );
};

export const GithubRepoPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GithubRepoPicker,
    name: 'GithubRepoPicker',
  }),
);
