import {
  createApiRef,
  IdentityApi,
  ConfigApi,
} from '@backstage/core-plugin-api';
import {
  Project,
  ProjectOwner,
  ProjectFilters,
} from '@internal/web-accessibility-common';

export const webAccessibilityApiRef = createApiRef<WebAccessibilityApi>({
  id: 'plugin.web-accessibility',
});

export interface WebAccessibilityApi {
  getProjects(filters?: ProjectFilters): Promise<Project[]>;
  upsertProjectOwner(projectOwner: ProjectOwner): Promise<void>;
}

export class WebAccessibilityClient implements WebAccessibilityApi {
  private readonly identityApi: IdentityApi;
  private readonly baseURL: string;

  constructor(options: { configApi: ConfigApi; identityApi: IdentityApi }) {
    this.identityApi = options.identityApi;
    this.baseURL = `${options.configApi.getString('backend.baseUrl')}`;
  }

  async getProjects(filters?: ProjectFilters): Promise<Project[]> {
    try {
      const projects = await this.do<undefined, { projects: Project[] }>(
        'projects',
        'GET',
        undefined,
        filters ? { ...filters } : undefined,
      );

      return projects!.projects;
    } catch (err) {
      throw new Error(`failed to get projects: ${err}`);
    }
  }

  async upsertProjectOwner(projectOwner: ProjectOwner): Promise<void> {
    try {
      const path = `projects/${projectOwner.project_id}/owner`;

      await this.do<ProjectOwner, void>(path, 'PATCH', projectOwner);
    } catch (err) {
      throw new Error(`failed to upsert project owner: ${err}`);
    }
  }

  private async getToken() {
    const { token } = await this.identityApi.getCredentials();

    return token;
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
  ): Promise<R | null> {
    const url = new URL(`api/web-accessibility/${path}`, this.baseURL);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    const token = await this.getToken();

    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const resp = await fetch(url.toString(), {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new Error(
        `axe monitor request failed with a ${resp.status} status: ${resp.statusText}`,
      );
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
