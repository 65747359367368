import {
  Entity,
  parseEntityRef,
  RELATION_MEMBER_OF,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  CatalogApi,
  catalogApiRef,
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import limiterFactory from 'p-limit';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/esm/useAsync';
import qs from 'qs';
import { uniq, uniqBy } from 'lodash';

export type EntityRelationAggregation = 'direct' | 'aggregated';

const limiter = limiterFactory(5);

type EntityTypeProps = {
  kind: string;
  type?: string;
  count: number;
};

const getQueryParams = (
  ownersEntityRef: string[],
  selectedEntity: EntityTypeProps,
): string => {
  const { kind, type } = selectedEntity;
  const owners = ownersEntityRef.map(owner =>
    humanizeEntityRef(parseEntityRef(owner), { defaultKind: 'group' }),
  );
  const filters = {
    kind: kind.toLocaleLowerCase('en-US'),
    type,
    owners,
    user: 'all',
  };
  return qs.stringify({ filters }, { arrayFormat: 'repeat' });
};

const getMemberOfEntityRefs = (owner: Entity): string[] => {
  const parentGroups = getEntityRelations(owner, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  const ownerGroupsNames = parentGroups.map(({ kind, namespace, name }) =>
    stringifyEntityRef({
      kind,
      namespace,
      name,
    }),
  );

  return [...ownerGroupsNames, stringifyEntityRef(owner)];
};

const isEntity = (entity: Entity | undefined): entity is Entity =>
  entity !== undefined;

const getChildOwnershipEntityRefs = async (
  entity: Entity,
  catalogApi: CatalogApi,
  alreadyRetrievedParentRefs: string[] = [],
): Promise<string[]> => {
  const childGroups = getEntityRelations(entity, RELATION_PARENT_OF, {
    kind: 'Group',
  });

  const hasChildGroups = childGroups.length > 0;

  const entityRef = stringifyEntityRef(entity);
  if (hasChildGroups) {
    const entityRefs = childGroups.map(r => stringifyEntityRef(r));
    const childGroupResponse = await limiter(() =>
      catalogApi.getEntitiesByRefs({
        fields: ['kind', 'metadata.namespace', 'metadata.name', 'relations'],
        entityRefs,
      }),
    );
    const childGroupEntities = childGroupResponse.items.filter(isEntity);

    const unknownChildren = childGroupEntities.filter(
      childGroupEntity =>
        !alreadyRetrievedParentRefs.includes(
          stringifyEntityRef(childGroupEntity),
        ),
    );
    const childrenRefs = (
      await Promise.all(
        unknownChildren.map(childGroupEntity =>
          getChildOwnershipEntityRefs(childGroupEntity, catalogApi, [
            ...alreadyRetrievedParentRefs,
            entityRef,
          ]),
        ),
      )
    ).flatMap(aggregated => aggregated);

    return uniq([...childrenRefs, entityRef]);
  }

  return [entityRef];
};

const getOwners = async (
  entity: Entity,
  relationAggregation: EntityRelationAggregation,
  catalogApi: CatalogApi,
): Promise<string[]> => {
  const isGroup = entity.kind === 'Group';
  const isAggregated = relationAggregation === 'aggregated';
  const isUserEntity = entity.kind === 'User';

  if (isAggregated && isGroup) {
    return getChildOwnershipEntityRefs(entity, catalogApi);
  }

  if (isAggregated && isUserEntity) {
    return getMemberOfEntityRefs(entity);
  }

  return [stringifyEntityRef(entity)];
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const batchGetOwnedEntitiesByOwners = async (
  owners: string[],
  kinds: string[],
  catalogApi: CatalogApi,
  batchSize: number = 100,
  delayMs: number = 100,
) => {
  const results = [];

  for (let i = 0; i < owners.length; i += batchSize) {
    const batch = owners.slice(i, i + batchSize);
    const response = await catalogApi.getEntities({
      filter: [
        {
          kind: kinds,
          'relations.ownedBy': batch,
        },
      ],
      fields: [
        'kind',
        'metadata.name',
        'metadata.namespace',
        'metadata.annotations',
        'spec.type',
        'relations',
      ],
    });

    results.push(...response.items);

    if (i + batchSize < owners.length) await delay(delayMs);
  }

  return uniqBy(results, stringifyEntityRef);
};

export function useGetEntities(
  entity: Entity,
  relationAggregation: EntityRelationAggregation,
  entityFilterKind?: string[],
  entityLimit = 10,
): {
  componentsWithCounters:
    | {
        counter: number;
        type: string;
        kind: string;
        queryParams: string;
      }[]
    | undefined;
  loading: boolean;
  error?: Error;
} {
  const catalogApi = useApi(catalogApiRef);
  const kinds = entityFilterKind ?? ['Component', 'API', 'System'];

  const {
    loading,
    error,
    value: componentsWithCounters,
  } = useAsync(async () => {
    const owners = await getOwners(entity, relationAggregation, catalogApi);

    const ownedEntitiesList = await batchGetOwnedEntitiesByOwners(
      owners,
      kinds,
      catalogApi,
    );

    // Get a list of GitHub Repositories from the owned entity list by looking at annotations
    const gitHubRepos: string[] = [];

    ownedEntitiesList.forEach(e => {
      if (!e.metadata.annotations) {
        return;
      }

      // example: url:https://github.com/healthline/infrastructure-live/tree/master/.backstage/healthy-engineering.yaml
      const url = e.metadata.annotations['backstage.io/managed-by-location'];

      // if the url doesn't start with url and github.com skip
      if (!url.startsWith('url:https://github.com/')) {
        return;
      }

      // extract what we care about
      const split = url.split('/');
      const org = split[3];
      const repo = split[4];

      if (!gitHubRepos.includes(`${org}/${repo}`)) {
        gitHubRepos.push(`${org}/${repo}`);
      }
    });

    const counts = ownedEntitiesList.reduce(
      (acc: EntityTypeProps[], ownedEntity) => {
        const match = acc.find(
          x => x.kind === ownedEntity.kind && x.type === ownedEntity.spec?.type,
        );
        if (match) {
          match.count += 1;
        } else {
          acc.push({
            kind: ownedEntity.kind,
            type: ownedEntity.spec?.type
              ? ownedEntity.spec?.type.toString()
              : ownedEntity.kind.toLocaleLowerCase(), // this is a fallback for entities that don't have a spec.type i.e. kind:System
            count: 1,
          });
        }
        return acc;
      },
      [],
    );

    // Return top N (entityLimit) entities to be displayed in ownership boxes
    const topN = counts.sort((a, b) => b.count - a.count).slice(0, entityLimit);

    const final = topN.map(topOwnedEntity => ({
      counter: topOwnedEntity.count,
      type: topOwnedEntity.type,
      kind: topOwnedEntity.kind,
      queryParams: getQueryParams(owners, topOwnedEntity),
    })) as Array<{
      counter: number;
      type: string;
      kind: string;
      queryParams: string;
    }>;

    // ad-hoc push a count for GitHub repositories
    final.push({
      counter: gitHubRepos.length,
      type: 'github-repo',
      kind: 'github-repo',
      queryParams: getQueryParams(owners, {
        kind: 'Component',
        count: gitHubRepos.length,
      }),
    });

    return final;
  }, [catalogApi, entity, relationAggregation]);

  return {
    componentsWithCounters,
    loading,
    error,
  };
}
