import {
  createPlugin,
  createComponentExtension,
  identityApiRef,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { incidentsApiRef, IncidentsClient } from './api/incidents';

export const incidentsPlugin = createPlugin({
  id: 'incidents',
  apis: [
    createApiFactory({
      api: incidentsApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => {
        return new IncidentsClient({ identityApi, configApi });
      },
    }),
  ],
});

export const EntityTeamPagerDutyTab = incidentsPlugin.provide(
  createComponentExtension({
    name: 'EntityTeamPagerDutyTab',
    component: {
      lazy: () =>
        import('./components/EntityTeamPagerDutyTab').then(
          m => m.EntityTeamPagerDutyTab,
        ),
    },
  }),
);

export const EntityIncidentsOverview = incidentsPlugin.provide(
  createComponentExtension({
    name: 'EntityIncidentsOverview',
    component: {
      lazy: () =>
        import('./components/EntityIncidentsOverview').then(
          m => m.EntityIncidentsOverview,
        ),
    },
  }),
);
