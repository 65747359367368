import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import { LinkButton } from '@backstage/core-components';

export type MetricCardProps = {
  title: string;
  headerAction?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  learnMorePath: string;
};

export const MetricCard = (props: MetricCardProps) => {
  return (
    <Card
      elevation={3}
      style={{
        width: '300px',
        minWidth: '300px',
        ...props.style,
      }}
      sx={{ m: 1 }}
    >
      <CardHeader title={props.title} action={props.headerAction ?? null} />
      <Divider />
      <CardContent>{props.children}</CardContent>
      <CardActions>
        <LinkButton size="small" to={props.learnMorePath}>
          Learn More
        </LinkButton>
      </CardActions>
    </Card>
  );
};
