import {
  ConfigApi,
  createApiRef,
  IdentityApi,
} from '@backstage/core-plugin-api';
import {
  CloudAccount,
  CloudAccountCost,
  CloudAccountCostData,
  isCloudAccountCostData,
  CostOpportunityData,
} from '@internal/cloud-accounts-common';

export interface CloudZeroCost {
  usage_date: string;
  cost: number;
  Account: string;
}

export const cloudAccountsApiRef = createApiRef<CloudAccountsApi>({
  id: 'plugin.cloud-accounts',
});

export class RequestError extends Error {
  resp: Response;
  status: number;

  constructor(resp: Response) {
    super(`cloud-accounts request failed with a ${resp.status} status code`);
    this.resp = resp;
    this.status = resp.status;

    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export interface CloudAccountsApi {
  getCloudAccounts(): Promise<CloudAccount[] | null>;
  updateCloudAccount(input: {
    accountID: string;
    accountType: 'aws' | 'azure' | 'gcp';
    businessUnit?: string;
    owner?: string;
  }): Promise<void>;
  getCloudAccountsCosts(): Promise<CloudAccountCost[]>;
  getCostOpportunitiesForTeam(teamId: string): Promise<CostOpportunityData>;
}

export class CloudAccountsClient implements CloudAccountsApi {
  private readonly baseURL: string;
  private readonly identityApi: IdentityApi;

  constructor(options: { configApi: ConfigApi; identityApi: IdentityApi }) {
    this.identityApi = options.identityApi;
    this.baseURL = options.configApi.getString('backend.baseUrl');
  }

  async getCloudAccounts(): Promise<CloudAccount[] | null> {
    try {
      const resp = await this.do<undefined, { accounts: CloudAccount[] }>(
        'accounts',
        'GET',
        undefined,
      );
      return resp?.accounts || null;
    } catch (err) {
      throw err;
    }
  }

  async getCloudAccountsCosts(): Promise<CloudAccountCost[]> {
    try {
      const resp = await this.do<undefined, CloudAccountCostData>(
        'accounts/cost',
        'GET',
        undefined,
      );

      if (!isCloudAccountCostData(resp)) {
        return [];
      }

      return resp.costData || [];
    } catch (err) {
      throw err;
    }
  }

  async updateCloudAccount(input: {
    accountID: string;
    accountType: 'aws' | 'azure' | 'gcp';
    businessUnit?: string;
    owner?: string;
  }): Promise<void> {
    const params: Record<string, string> = {};

    if (input.businessUnit) {
      params.businessUnit = input.businessUnit;
    }

    if (input.owner) {
      params.owner = input.owner;
    }

    try {
      await this.do<unknown, unknown>(
        `accounts/${input.accountType}/${input.accountID}`,
        'PATCH',
        undefined,
        params,
      );
    } catch (err) {
      throw err;
    }
  }

  async getCostOpportunitiesForTeam(
    team: string,
  ): Promise<CostOpportunityData> {
    try {
      const resp = await this.do<undefined, CostOpportunityData>(
        'accounts/ops',
        'GET',
        undefined,
        { group: team },
      );

      return (
        resp! || {
          trustedAdvisor: [],
        }
      );
    } catch (err) {
      throw err;
    }
  }

  private async getToken() {
    const { token } = await this.identityApi.getCredentials();

    return token;
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
  ): Promise<R | null> {
    const url = new URL(`/api/cloud-accounts/${path}`, this.baseURL);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    const token = await this.getToken();

    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const resp = await fetch(url.toString(), {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new RequestError(resp);
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
