import React from 'react';
import {
  ExploreLayout,
  ToolExplorerContent,
} from '@backstage-community/plugin-explore';

export const ExplorePage = () => {
  return (
    <ExploreLayout title="Explore Tools">
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
