import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { QuestionMarkRounded } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { BaseTile } from './BaseTile';
import { LinkButton } from '@backstage/core-components';
import { useUserCloudCosts } from '../../../../../hooks/useUserCloudCosts';

export const CloudCostTile: React.FC = () => {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(lastMonth.getMonth() - 2);

  const { value, loading, error } = useUserCloudCosts();

  const lastMonthCost =
    value?.reduce((acc, val) => {
      return (
        acc +
        (val.costData.find(
          data =>
            data.month === lastMonth.getUTCMonth() &&
            data.year === lastMonth.getUTCFullYear(),
        )?.cost ?? 0)
      );
    }, 0) ?? 0;

  const twoMonthsAgoCost =
    value?.reduce((acc, val) => {
      return (
        acc +
        (val.costData.find(
          data =>
            data.month === twoMonthsAgo.getUTCMonth() &&
            data.year === twoMonthsAgo.getUTCFullYear(),
        )?.cost ?? 0)
      );
    }, 0) ?? 0;

  const costTrend = lastMonthCost - twoMonthsAgoCost > 0 ? 'more' : 'less';
  const costDifferenceAbs = Math.abs(lastMonthCost - twoMonthsAgoCost);

  let content;
  if (loading) {
    content = <Typography variant="h4">Loading...</Typography>;
  } else if (error) {
    content = <Typography variant="h4">{JSON.stringify(error)}</Typography>;
  } else {
    content = (
      <Stack>
        <Typography
          variant="h4"
          align="center"
          color={costTrend === 'less' ? 'primary' : 'secondary'}
        >
          {costDifferenceAbs.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {`${costTrend} spent in ${lastMonth.toLocaleString('default', {
            month: 'long',
          })} than ${twoMonthsAgo.toLocaleString('default', {
            month: 'long',
          })} across all accounts`}
        </Typography>
      </Stack>
    );
  }

  return (
    <BaseTile
      title="Cloud Cost"
      headerAction={
        <Tooltip title="The total cost of all cloud accounts owned by the user or their teams.">
          <IconButton aria-label="cloud-cost-info">
            <QuestionMarkRounded />
          </IconButton>
        </Tooltip>
      }
      cardActions={
        <LinkButton size="small" to="/me/cloud-cost">
          Learn More
        </LinkButton>
      }
    >
      {content}
    </BaseTile>
  );
};
