import React, { useState, useEffect } from 'react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Entity } from '@backstage/catalog-model';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { RELATION_OWNED_BY } from '@backstage/catalog-model';

type InfraRepoOption = {
  org: string;
  repo: string;
  backstageSlug: string;
};

export const InfraRepoPicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: {
      title = 'Infrastructure GitHub Repo',
      description = 'What is the GitHub repo you would like to use for your infrastructure?',
    },
    rawErrors = [],
    formData = {},
  } = props;

  // used to set available selectable options for the field
  const [options, setOptions] = useState<InfraRepoOption[]>([]);

  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    // fetch IAC repos from catalog
    const loadRepos = async () => {
      const token = await identityApi.getCredentials();
      const identity = await identityApi.getBackstageIdentity();
      const repos = await catalogApi.getEntities(
        {
          filter: {
            kind: ['Component'],
            'spec.type': 'iac-pipeline',
            // only show repos that the user has ownership of
            [`relations.${RELATION_OWNED_BY}`]: identity.ownershipEntityRefs,
          },
        },
        {
          token: token.token,
        },
      );
      setOptions(repos.items.map(repo => getInfraRepoOptionFromEntity(repo)));
    };
    loadRepos();
  }, [identityApi, catalogApi]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors.length > 0 && !formData}
    >
      <Autocomplete
        value={(formData as string) || ''}
        loading={false}
        options={options}
        onChange={(_, value) => {
          if (!value) {
            onChange('');
            return;
          }

          if (typeof value === 'string') {
            onChange(value);
            return;
          }

          onChange(value.backstageSlug);
        }}
        freeSolo
        // used to display the string value of the option in the dropdown
        getOptionLabel={option => {
          if (!option) {
            return '';
          }

          if (JSON.stringify(option) === '{}') {
            return '';
          }

          // TODO: why do we need to do this?
          if (typeof option === 'string') {
            const x = option as string;

            const repo = x.split('&')[0].split('=')[1];
            const org = x.split('&')[1].split('=')[1];

            return `${org}/${repo}`;
          }

          return `${option.org}/${option.repo}`;
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};

export const InfraRepoPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: InfraRepoPicker,
    name: 'InfraRepoPicker',
    // this allows us to test at the create/edit page
    schema: {
      returnValue: { type: 'string' },
    },
  }),
);

export function getInfraRepoOptionFromEntity(entity: Entity): InfraRepoOption {
  const infraRepoOption: InfraRepoOption = {
    org: '',
    repo: '',
    backstageSlug: '',
  };

  if (!entity.metadata.annotations) {
    return infraRepoOption;
  }

  // example: url:https://github.com/healthline/infrastructure-live/tree/master/.backstage/healthy-engineering.yaml
  const url = entity.metadata.annotations['backstage.io/managed-by-location'];

  // if the url doesn't start with url and github.com skip
  if (!url.startsWith('url:https://github.com/')) {
    return infraRepoOption;
  }

  // extract what we care about
  const split = url.split('/');
  infraRepoOption.org = split[3];
  infraRepoOption.repo = split[4];
  infraRepoOption.backstageSlug = `repo=${infraRepoOption.repo}&owner=${infraRepoOption.org}`;

  return infraRepoOption;
}
