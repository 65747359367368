import {
  createComponentExtension,
  createPlugin,
  createRouteRef,
  createApiFactory,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  securityMetricsApiRef,
  SecurityMetricsClient,
} from './api/security-metrics';

export const entityContentRouteRef = createRouteRef({
  id: 'security-metrics',
});

export const securityMetricsPlugin = createPlugin({
  id: 'security-metrics',
  apis: [
    createApiFactory({
      api: securityMetricsApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => {
        return new SecurityMetricsClient({ identityApi, configApi });
      },
    }),
  ],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityGroupSecurityMetricsTab = securityMetricsPlugin.provide(
  createComponentExtension({
    name: 'EntityGroupSecurityMetricsTab',
    component: {
      lazy: () =>
        import('./components/GroupSecurityMetricsTab').then(
          m => m.GroupSecurityMetricsTab,
        ),
    },
  }),
);

export const EntityResourceSecurityMetricsTab = securityMetricsPlugin.provide(
  createComponentExtension({
    name: 'EntityResourceSecurityMetricsTab',
    component: {
      lazy: () =>
        import('./components/ResourceSecurityMetricsTab').then(
          m => m.ResourceSecurityMetricsTab,
        ),
    },
  }),
);

export const EntitySecurityOverview = securityMetricsPlugin.provide(
  createComponentExtension({
    name: 'EntitySecurityOverview',
    component: {
      lazy: () =>
        import('./components/EntitySecurityOverview').then(
          m => m.EntitySecurityOverview,
        ),
    },
  }),
);
