import { Link } from '@backstage/core-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart } from '@mui/x-charts';
import React from 'react';
import { CloudAccountCost, CostRecord } from '@internal/cloud-accounts-common';
import { CloudZeroAccountLink } from '../../CloudZeroLinks';
import { CostRecordDataPoint } from '../utils';

export type CloudCostTableProps = {
  costRecords: Record<string, CloudAccountCost>;
  splitCurrentMonth?: boolean;
};

export const CloudCostsTable: React.FC<CloudCostTableProps> = ({
  costRecords,
  splitCurrentMonth = true,
}) => {
  const toDataset = (
    cloudCosts?: CostRecord[],
  ): [boolean, CostRecordDataPoint[]] => {
    const now = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setUTCFullYear(now.getUTCFullYear() - 1);

    let dataset: CostRecordDataPoint[] =
      cloudCosts
        ?.map(v => ({
          ...v,
          date: new Date(v.year, v.month),
        }))
        .sort((a, b) => b.date.getUTCDate() - a.date.getUTCDate()) ?? [];

    // if the cost data doesn't go back a year, pad it with nulls for missing months
    if (
      dataset.length === 0 || // there is no cost data
      (dataset[0].year !== oneYearAgo.getUTCFullYear() && // cloud data doesn't go
        dataset[0].month !== oneYearAgo.getUTCMonth())
    ) {
      const missingMonths: CostRecordDataPoint[] = [];
      const currentDate = new Date(oneYearAgo);
      // if there is no existing data, pad up to today
      const stopDate =
        dataset.length > 0
          ? new Date(dataset[0].year, dataset[0].month)
          : new Date();

      while (currentDate < stopDate) {
        const year = currentDate.getUTCFullYear();
        const month = currentDate.getUTCMonth();
        if (!dataset.find(v => v.year === year && v.month === month)) {
          missingMonths.push({
            cost: null,
            year,
            month,
            date: new Date(year, month),
          });
        }
        currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
      }

      dataset = [...missingMonths, ...dataset];
    }

    let hasCurrentMonth = false;
    if (dataset.length !== 0) {
      const last = dataset[dataset.length - 1];
      // if the last record is for the current month, create modified record of the cost for display purposes
      if (
        last.date.getFullYear() === now.getFullYear() &&
        last.date.getMonth() === now.getMonth() &&
        splitCurrentMonth
      ) {
        dataset[dataset.length - 1] = {
          ...last,
          cost: null,
          currentCost: last.cost!,
        };
        hasCurrentMonth = true;
      }
    }

    return [hasCurrentMonth, dataset];
  };

  const tableBody = Object.entries(costRecords ?? {})
    .sort((a, b) => {
      // compare the last cost record for each account

      const firstAccountCostData = a[1].costData;
      const secondAccountCostData = b[1].costData;

      const aCost = firstAccountCostData[firstAccountCostData.length - 1].cost;
      const bCost =
        secondAccountCostData[secondAccountCostData.length - 1].cost;
      return bCost - aCost;
    })
    .map(([accountId, accountData]) => {
      const [hasCurrentMonth, dataset] = toDataset(accountData.costData);
      const series = [
        {
          dataKey: 'cost',
          label: 'Historical Cost',
          valueFormatter: (v: number | null) =>
            v?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) ?? '',
        },
      ];
      if (hasCurrentMonth) {
        series.push({
          dataKey: 'currentCost',
          label: 'Current Month Cost',
          valueFormatter: (v: number | null) =>
            v?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) ?? '',
        });
      }
      return (
        <TableRow key={accountId}>
          <TableCell>
            <Link to={`/catalog/default/resource/${accountId}`}>
              {accountData.accountName}
            </Link>
          </TableCell>
          <TableCell>
            <LineChart
              dataset={dataset}
              xAxis={[
                {
                  dataKey: 'date',
                  scaleType: 'time',
                  tickNumber: dataset.length, // required to prevent D3 from inserting extra ticks
                  valueFormatter: (v: Date) =>
                    v.toLocaleString('default', {
                      year: 'numeric',
                      month: 'short',
                    }),
                  labelStyle: { fill: '#FFFFFF' },
                  tickLabelStyle: { fill: '#FFFFFF' },
                },
              ]}
              yAxis={[
                {
                  min: 0,
                  max: Math.max(
                    100,
                    dataset.reduce((acc, v) => Math.max(acc, v.cost ?? 0), 0),
                  ),
                  valueFormatter: (v: number | null) =>
                    v?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }) ?? '',
                  labelStyle: { fill: '#FFFFFF' },
                  tickLabelStyle: { fill: '#FFFFFF' },
                },
              ]}
              series={series}
              height={300}
              grid={{ vertical: true, horizontal: true }}
            />
            <CloudZeroAccountLink accountId={accountId} />
          </TableCell>
        </TableRow>
      );
    });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};
