import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { useGithubToken } from '../utils';
import { Octokit } from '@octokit/rest';

type InfraEnvOption = {
  label: string;
  name: string;
  stateBucket: string;
  dynamodbTable: string;
  region: string;
};

export const InfraEnvPicker = (
  props: FieldExtensionComponentProps<InfraEnvOption>,
) => {
  const {
    onChange,
    required,
    schema: { title = 'Environment Selector' },
    rawErrors = [],
    formData,
    formContext,
  } = props;

  const token = useGithubToken();

  // used to set available selectable options for the field
  const [options, setOptions] = useState<InfraEnvOption[]>([]);
  useEffect(() => {
    (async () => {
      if (!formContext.formData.infraRepo || !token) {
        return;
      }
      const octokit = new Octokit({ auth: token });
      // parse the repo and owner from a string like: "repo=tf-developer-platforms&owner=rvohealth" at formContext.formData.infraRepo
      const repo = formContext.formData.infraRepo.split('&')[0].split('=')[1];
      const org = formContext.formData.infraRepo.split('&')[1].split('=')[1];

      // get contents of config.json from repo
      const result = await octokit.repos.getContent({
        repo,
        owner: org,
        path: 'config.json',
      });

      // decode result.data.content

      if (!('content' in result.data)) {
        return;
      }

      const config = JSON.parse(atob(result.data.content)) as {
        accountId: string;
        environment?: string;
        configDirectory: string;
        rootModules: string[];
      }[];

      const loadedOptions = await Promise.all(
        config.map(async c => {
          // strip ./ from the start of configDirectory if it exists
          const cleanConfigDirectory = c.configDirectory.startsWith('./')
            ? c.configDirectory.slice(2)
            : c.configDirectory;

          const backendResult = await octokit.repos.getContent({
            repo,
            owner: org,
            path: `${cleanConfigDirectory}/backend.tf`,
          });
          if (!('content' in backendResult.data)) {
            throw new Error('no content');
          }

          const tf = atob(backendResult.data.content);

          const backendConfig = tf.match(/(?<=backend "s3" {)(.*?)(?=})/gs)![0];
          const lines = backendConfig.split('\n');
          const backend: Record<string, any> = {};
          for (const line of lines) {
            if (line.trim() !== '') {
              const [key, value] = line.split('=');
              backend[key.trim()] = value.trim().replace(/"/g, '');
            }
          }

          // determine postfix by removing the prefix 'terraform_locks' from the dynamodb_table
          backend.postfix = backend.dynamodb_table.replace(
            'terraform_locks',
            '',
          );

          const name = c.environment || cleanConfigDirectory.split('/')[1];

          return {
            label: `${c.accountId}(${name})`,
            accountId: c.accountId,
            name,
            stateBucket: backend.bucket,
            dynamodbTable: backend.dynamodb_table,
            region: backend.region,
          };
        }),
      );

      setOptions(loadedOptions);
    })();
  }, [formContext.formData.infraRepo, token]);

  const fd = formData as InfraEnvOption | {};

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors.length > 0 && !formData}
    >
      <Autocomplete<InfraEnvOption>
        value={Object.keys(fd).length ? (fd as InfraEnvOption) : null}
        loading={false}
        options={options}
        disabled={!formContext.formData.infraRepo}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={(_, value) => {
          if (value) {
            onChange(value);
          }
        }}
        // used to display the string value of the option in the dropdown
        getOptionLabel={option => option.label || ''}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};

export const InfraEnvPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: InfraEnvPicker,
    name: 'InfraEnvPicker',
    // this allows us to test at the create/edit page
    schema: {
      returnValue: { type: 'string' },
    },
  }),
);
