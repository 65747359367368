import { FaGithub } from 'react-icons/fa';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { SiGooglecloud } from 'react-icons/si';
import { IoIosDocument } from 'react-icons/io';
import { LuPackageOpen } from 'react-icons/lu';
import { SiTerraform } from 'react-icons/si';
import { MdOutlineGroupWork } from 'react-icons/md';
import { MdWeb } from 'react-icons/md';
import { BiSolidComponent } from 'react-icons/bi';
import { TbApi } from 'react-icons/tb';
import { GrAppsRounded } from 'react-icons/gr';
import { GrSystem } from 'react-icons/gr';

export const ICONS = {
  'github-repo': FaGithub,
  'aws-account': FaAws,
  'azure-subscription': SiMicrosoftazure,
  'gcp-project': SiGooglecloud,
  'terraform-repo': SiTerraform,
  'terraform-module': SiTerraform,
  'iac-pipeline': SiTerraform,
  system: GrSystem,
  openapi: TbApi,
  component: BiSolidComponent,
  library: LuPackageOpen,
  service: MdOutlineGroupWork,
  website: MdWeb,
  documentation: IoIosDocument,
  default: GrAppsRounded,
};

export const TYPE_ORDER = [
  'github-repo',
  'aws-account',
  'azure-subscription',
  'gcp-project',
  'system',
  'service',
  'website',
  'openapi',
  'library',
  'documentation',
  'iac-pipeline',
  'terraform-repo',
  'terraform-module',
];

export const GRADIENTS = {
  'github-repo': ['rgba(36,41,46,0.6)', 'rgba(96,120,144,0.6)'],
  'aws-account': ['rgba(255,153,0,0.6)', 'rgba(255,204,153,0.6)'],
  'azure-subscription': ['rgba(0,120,212,0.6)', 'rgba(100,187,255,0.6)'],
  'gcp-project': ['rgba(60,103,176,0.6)', 'rgba(197,202,233,0.6)'],
  'terraform-repo': ['rgba(101,187,174,0.6)', 'rgba(84,164,153,0.6)'],
  'terraform-module': ['rgba(50,137,124,0.6)', 'rgba(33,114,101,0.6)'],
  'iac-pipeline': ['rgba(0,180,219,0.6)', 'rgba(102,50,143,0.6)'],
  openapi: ['rgba(35,37,38,0.6)', 'rgba(65,67,69,0.6)'],
  component: ['rgba(58,123,213,0.6)', 'rgba(58,96,115,0.6)'],
  library: ['rgba(95,44,130,0.6)', 'rgba(73,160,157,0.6)'],
  service: ['rgba(31,28,44,0.6)', 'rgba(146,141,171,0.6)'],
  website: ['rgba(22,34,42,0.6)', 'rgba(58,96,115,0.6)'],
  documentation: ['rgba(0,0,0,0.6)', 'rgba(83,52,109,0.6)'],
  default: ['rgba(44,62,80,0.6)', 'rgba(189,195,199,0.6)'],
};
