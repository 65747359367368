import {
  createApiRef,
  IdentityApi,
  ConfigApi,
} from '@backstage/core-plugin-api';
import {
  EscalationPoliciesResponse,
  PagerDutyIncidentsResponse,
  OnCallsResponse,
  Oncall,
} from '@internal/incidents-common';

export const incidentsApiRef = createApiRef<incidentsApi>({
  id: 'plugin.incidents',
});

export class RequestError extends Error {
  resp: Response;
  status: number;

  constructor(resp: Response) {
    super(`incidents request failed with a ${resp.status} status code`);
    this.resp = resp;
    this.status = resp.status;

    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export interface incidentsApi {
  getIncidentsforTeams(
    teamIds: string[],
    monthsAgo: number,
  ): Promise<PagerDutyIncidentsResponse>;
  getOnCallMembersForTeams(teamIds: string[]): Promise<Oncall[]>;
}

export class IncidentsClient implements incidentsApi {
  private readonly identityApi: IdentityApi;
  private readonly baseURL: string;

  constructor(options: { identityApi: IdentityApi; configApi: ConfigApi }) {
    this.identityApi = options.identityApi;
    this.baseURL = options.configApi.getString('backend.baseUrl');
  }

  async getOnCallMembersForTeams(teamIds: string[]): Promise<Oncall[]> {
    // get escalation policies for the teams
    const escalationPolicyResp = await this.do<
      undefined,
      EscalationPoliciesResponse
    >(`escalation_policies`, 'GET', undefined, undefined, {
      team_ids: teamIds,
    });

    if (!escalationPolicyResp) {
      return [];
    }

    // get oncalls for the escalation policies
    const onCallsResp = await this.do<undefined, OnCallsResponse>(
      `oncalls`,
      'GET',
      undefined,
      undefined,
      {
        escalation_policy_ids: escalationPolicyResp.escalation_policies.map(
          p => p.id,
        ),
      },
    );

    if (!onCallsResp) {
      return [];
    }

    return onCallsResp.oncalls;
  }

  async getIncidentsforTeams(
    teamIds: string[],
    monthsAgo: number,
  ): Promise<PagerDutyIncidentsResponse> {
    const queryParams: Record<string, string> = {};
    const arrayQueryParams: Record<string, string[]> = {};

    // Set the date range to the last N months
    const today = new Date();
    const before = new Date(today);
    before.setMonth(today.getMonth() - monthsAgo);

    queryParams.since = before.toISOString();
    queryParams.until = today.toISOString();
    queryParams.sort_by = 'created_at:desc';

    arrayQueryParams.team_ids = teamIds;

    const resp = await this.do<undefined, PagerDutyIncidentsResponse>(
      'incidents',
      'GET',
      undefined,
      queryParams,
      arrayQueryParams,
    );

    if (!resp) {
      return { incidents: [], limit: 0, offset: 0, more: false };
    }

    return resp;
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
    arrayParams?: Record<string, string[]>,
  ): Promise<R | null> {
    const url = new URL(`/api/proxy/pagerduty/${path}`, this.baseURL);

    const searchParams = new URLSearchParams();

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, value);
      }
    }

    // Append query parameters, if any, to the URL
    if (arrayParams) {
      for (const [key, value] of Object.entries(arrayParams)) {
        if (Array.isArray(value)) {
          value.forEach(v => searchParams.append(`${key}[]`, v));
        } else {
          searchParams.set(key, value);
        }
      }
    }

    url.search = searchParams.toString();

    const { token } = await this.identityApi.getCredentials();
    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const resp = await fetch(url.toString(), {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new RequestError(resp);
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
